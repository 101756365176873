import { EventContext } from '../../context/event-context';
import { CallToActionOverlay } from './call-to-action-overlay';
import { CookieConsent } from './cookie-consent';
import { QueryParamTrigger } from './query-param-trigger';
import { ProjectSpecificPageWrapperProps } from './types';
import { AnalyticsTransformer, LoadAnalytics } from '@cue/utility';
import { transformEventData } from '@project/cms/helpers';
import { useUser } from '@project/hooks/use-user';
import { ModalProvider } from '@project/providers/modal-provider';
import { useEffect } from 'react';

export const ProjectSpecificPageWrapper = (props: ProjectSpecificPageWrapperProps) => {
  const {
    data,
    pageContext: { lang },
  } = props;
  const { user, loading: userLoading } = useUser();
  const eventsData = transformEventData(data, lang);

  useEffect(() => {
    if (!userLoading && user) AnalyticsTransformer.setUserData();
  }, [userLoading, user]);

  return (
    <EventContext.Provider value={eventsData}>
      <ModalProvider path={props.path} state={props.location.state}>
        <QueryParamTrigger>
          <CallToActionOverlay {...props}>{props.children}</CallToActionOverlay>
          {!userLoading && <LoadAnalytics />}
          <CookieConsent />
        </QueryParamTrigger>
      </ModalProvider>
    </EventContext.Provider>
  );
};
