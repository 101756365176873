import { ConfigContextProvider } from './src/hooks/use-config';
import i18next from './src/i18n/config';
import { AuthenticationProvider } from './src/providers/auth-provider';
import { FlagsProvider } from './src/providers/flags-provider';
import buehlerTheme from './src/themes/buehler/buehlerTheme';
import { CueThemesProvider } from '@cue/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { SingletonHooksContainer } from 'react-singleton-hook';

const queryClient = new QueryClient();

export const RootElement = ({ children }) => {
  return (
    <ConfigContextProvider>
      <QueryClientProvider client={queryClient}>
        <AuthenticationProvider>
          <FlagsProvider>
            <I18nextProvider i18n={i18next}>
              <CueThemesProvider themes={{ buehler: buehlerTheme }} defaultTheme="buehler">
                <SingletonHooksContainer />
                {children}
              </CueThemesProvider>
            </I18nextProvider>
          </FlagsProvider>
        </AuthenticationProvider>
      </QueryClientProvider>
    </ConfigContextProvider>
  );
};
