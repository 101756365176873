import { EVENTS_PATH } from '../../constants';
import { TEvents } from '../context/event-context';
import { getTranslationsForLanguage, parseGatsbyImage } from './pre-hooks';
import { TimeUtil } from '@cue/utility';
import { DirectusMenu } from '@project/hooks/use-nav-menu/types';

export const transformEventSubmenu = (menu: any[], path: string): DirectusMenu[] => {
  return menu
    .map((m) => m?.events_menu_id)
    .filter((m) => !!m?.submenu)
    .map((m) => {
      const { submenu: _, ...rest } = m;
      return {
        ...rest,
        target: 'all',
        menu_items: m.submenu.map((sm: any) => ({
          menu_items_id: {
            ...sm.menu_items_id,
            link: `${path}${sm.menu_items_id.link}`,
          },
        })),
      };
    });
};

export const transformEventData = (data: any, lang: string): TEvents => {
  if (!data?.directus?.events || data?.directus?.events.length === 0) {
    return [];
  }

  return data?.directus?.events
    .filter((event: any) => !!event.info)
    .map((event: any) => {
      const content = getTranslationsForLanguage(event.info, lang || 'en');

      return {
        content,
        image: content.key_visual ? parseGatsbyImage(content.key_visual) : undefined,
        desired_user_fields:
          event.info.desired_user_fields?.map((f: any) => f.user_fields_id) || [],
        startDate: TimeUtil.getUtc(event.info.start_date),
        endDate: TimeUtil.getUtc(event.info.end_date),
        slug: event.slug,
        isPastEvent: isPastEvent(event.info.end_date),
        date_created: TimeUtil.getUtc(event.info.date_created),
        categories: event.info.categories?.map((c: any) => c.categories_id) || [],
        submenu: transformEventSubmenu(event.submenu || [], `${EVENTS_PATH}/${event.slug?.slug}`),
      };
    });
};

export const isPastEvent = (date: string) => {
  return TimeUtil.getNowUtc().isAfter(TimeUtil.getUtc(date));
};
