// import { ResendAccessPopup } from '@cue/site/resend-access-popup';
import { useQueryParams } from '@cue/hooks';
// import { AdhocTalkRequest } from '@cue/site/adhoc';
import { Modal, useModal } from '@cue/organisms';
import { useUser } from '@project/hooks/use-user';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React from 'react';

// TODO: This is not a provider -> Find a good place
// TODO: Make Component more flexible and better to

interface QueryParamTriggerProps {
  children?: React.ReactNode;
}

export const QueryParamTrigger: React.FC<QueryParamTriggerProps> = ({ children }) => {
  const location = useLocation();
  const [params, setParams] = useQueryParams({
    location,
    navigate: navigate,
  });
  const { isAuthenticated, user, loading } = useUser();
  const [resendAccessModalRef] = useModal();
  const [adHocModalRef] = useModal();

  const { action } = params;

  const removeActionParam = React.useCallback(() => {
    if (params.action) {
      setParams({ ...params, action: undefined });
    }
  }, [params, setParams]);

  React.useEffect(() => {
    if (!action || loading) return;

    switch (action) {
      case 'resend-access-popup':
        !isAuthenticated && resendAccessModalRef.current?.open();
        break;

      case 'adhoc-modal':
        isAuthenticated && adHocModalRef.current?.open();
        break;

      default:
        console.warn(`No trigger for action ${action}`);
    }
  }, [action, isAuthenticated, user, loading, resendAccessModalRef, adHocModalRef]);

  return (
    <>
      {children}
      <Modal
        ref={resendAccessModalRef}
        onVisibilityChange={(isOpen) => !isOpen && removeActionParam()}>
        <h1>ResendAccessPopup</h1>
        {/* <ResendAccessPopup isLoggedIn={isAuthenticated || false} /> */}
      </Modal>
      <Modal ref={adHocModalRef} onVisibilityChange={(isOpen) => !isOpen && removeActionParam()}>
        <h1>AdhocTalkRequest</h1>
        {/* <AdhocTalkRequest /> */}
      </Modal>
    </>
  );
};
