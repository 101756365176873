export const iconMapping: Record<string, CueIconName | string> = {
  icon: 'arrow-down',
  arrow: 'arrow-down',
  default: 'sign-language',
  close: 'close',
  check: 'check',
  arrowRight: 'disclosure-right',
  arrowLeft: 'disclosure-left',
  home: 'home',
  back: 'arrow-up-left',
  videostoryClose: 'arrow-double-right',
  indicatorActive: 'check',
  language: 'language',
  menu: 'menu',
  play: 'play',
  pause: 'pause',
  soundOn: 'horn-on',
  soundOff: 'sound-off',
  fullscreenOn: 'fullscreen-leave-arrow',
  fullscreenOff: 'fit-to-screen',
  favourite: 'favourite',
  favouriteMarked: 'favourite_marked',
  calendar: 'calendar',
  tagSelected: 'close',
  resize: 'resize',
  tiltdevice: 'refresh',
  stop: 'stop',
  quit: 'logout',
  undo: 'undo',
  link_extern: 'link_extern',
  arrowRightSecondary: 'arrow-double-right',
  documentPdf: 'document-pdf',
  app: 'apps',
  info: 'information',
  dropdown: 'disclosure-down',
  virtual: 'display',
};
