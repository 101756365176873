import { iconMapping } from './icon-mapping';
import iconsSprite from './icons/sprite.svg';
import _cq, { cqTypes } from './scss/cq/cq';
import _mq, { mqTypes } from './scss/mq/mq';
import './scss/style.scss';

const containerQueries: Record<string, string> = {};

export const cq = (size: keyof cqTypes): string => {
  return _cq[size] ? `@container (min-width: ${_cq[size]}px)` : '';
};

for (const key in _cq) {
  const querySelector = key as keyof cqTypes;
  const value = _cq[querySelector];
  if (typeof value === 'string') containerQueries[key] = cq(querySelector);
}
const mediaQueries: Record<string, string> = {};

export const mq = (size: keyof mqTypes): string => {
  return _mq[size] ? `@media (min-width: ${_mq[size]}px)` : '';
};
for (const key in _mq) {
  const querySelector = key as keyof mqTypes;
  const value = _mq[querySelector];
  if (typeof value === 'string') mediaQueries[key] = mq(querySelector);
}

export default {
  iconSpriteSheet: iconsSprite,
  mediaQueries,
  iconMapping,
  containerQueries,
};
