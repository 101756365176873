import { PageContext } from '../../../../gatsby-node';
import { mustache } from '@cue/utility';
import { Helmet } from 'react-helmet';

type PageMetaProps = {
  pageContext: PageContext;
};

export const PageMeta: React.FC<PageMetaProps> = (props) => {
  const { pageContext } = props;
  const { pageInfo, globalOptions } = pageContext;

  const pageTitle =
    pageInfo &&
    mustache(globalOptions?.seo_page_title, {
      globalOptions,
      pageInfo,
    });

  return (
    <Helmet>
      {pageTitle ? <title>{pageTitle}</title> : null}
      {pageInfo?.meta_description ? (
        <meta name="description" content={pageInfo.meta_description} />
      ) : null}
    </Helmet>
  );
};

export const TestComponent = () => {
  return <p>Hi from Test</p>;
};
