import { ApiConfig, EventConfig, apiConfig, eventConfig } from '../../config';
import React from 'react';

const projectConfig = {
  apiConfig,
  eventConfig,
};

export const ConfigContext = React.createContext<ConfigContextProps>(projectConfig);
export interface ConfigContextProps {
  apisURIs?: {
    events?: string;
    concierge?: string;
    content?: string;
    chatToken?: string;
  };
  apiConfig: ApiConfig;
  eventConfig: EventConfig;
}

export const ConfigContextProvider: React.FC<{
  children?: React.ReactNode;
  config?: ConfigContextProps;
}> = (props) => {
  if (props.config) {
    return <ConfigContext.Provider value={props.config}>{props.children}</ConfigContext.Provider>;
  }
  return <ConfigContext.Provider value={projectConfig}>{props.children}</ConfigContext.Provider>;
};

export const useConfig = () => React.useContext(ConfigContext);
